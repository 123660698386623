/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Logo from "../resources/images/Elliate_logo_white.svg"
import "./layout.css"
import "../resources/css/style.css"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Footer from "./footer"

export default function Layout({ children }){

  

  return (
    <>
      <div className="container">
        <nav>
          
        <AniLink cover direction="right" to="/" bg="#000" duration={1}><div className="logo"><img width="154.75" height="36.44" src={Logo} alt="Elliate"></img></div></AniLink>
          <div className="menu">
            <input type="checkbox" id="nav" className="hidden"/>
            <label for="nav" className="nav-open"><i></i><i></i><i></i></label>
            <div className="nav-container">
              <ul>
                <li><div className="subnav"><AniLink style={{paddingRight:"0"}} cover direction="left" to="/web-aplikacie" bg="#000" duration={1}>Služby</AniLink>
                  <div className="submenu-content">
                    <AniLink cover direction="left" to="/web-aplikacie" bg="#000" duration={1}>Web Aplikácie<span className="separator"> --</span></AniLink>
                    <AniLink cover direction="left" to="/mobilne-aplikacie" bg="#000" duration={1}>Mobilné Aplikácie<span className="separator"> --</span></AniLink>
                    <AniLink cover direction="left" to="/umela-inteligencia" bg="#000" duration={1}>Umelá Inteligencia<span className="separator"> --</span></AniLink>
                    <AniLink cover direction="left" to="/big-data" bg="#000" duration={1}>Big Data<span className="separator"> --</span></AniLink>
                    <AniLink cover direction="left" to="/outsource" bg="#000" duration={1}>Outsourcing<span className="separator"> --</span></AniLink>
                  </div></div></li>
                
                <li><AniLink cover direction="left" to="/referencie" bg="#000" duration={1}>Referencie</AniLink></li>
                <li><AniLink cover direction="left" to="/ako-to-robime" bg="#000" duration={1}>Ako to robíme</AniLink></li>
                <li><AniLink cover direction="left" to="/o-nas" bg="#000" duration={1}>O nás</AniLink></li>
                <li><AniLink cover direction="left" to="/kontakt" bg="#000" duration={1}>Kontakt</AniLink></li>

              </ul>
            </div>
          </div>
        </nav>
        </div>
        <main>{children}</main>
 
        <Footer />

        
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}


