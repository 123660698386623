import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";

export default function Ultimuv() {

  const data = useStaticQuery(graphql`{
  ultimuv: file(relativePath: {eq: "ultimuv2.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`)



  return (
    <Layout>
      <SEO title="Referencie" />
      <GatsbyImage
        image={data.ultimuv.childImageSharp.gatsbyImageData}
        objectFit="cover"
        objectPosition="0% 0%"
        alt="Web stránka" />  
      <div className="section-pd section-pd-bottom white-bg">
        <div className="container">
          <div className="row">
          <div className="col-12">
              <h1>Web, webová aplikácia a mobilná aplikácia pre pohybové centrá Ultimuv</h1>
              <p>Ultimuv je projekt, ktorý pomáha ľuďom s ich chrbtovými problémami, zlými návyky v chôdzi a behu. Spolupracujú s niekoľkými najlepšími bežcami na Slovensku.
<br></br><br></br>
Vytvorili sme webovú stránku, mobilnú aplikáciu a softvér na mieru.</p>
            </div>
            
          </div>
          
        </div>
      </div>
     
         
          

      
      



      <div className="ref-buttons">
        <AniLink className="back-to-ref" cover direction="left" to="/referencie" bg="#000" duration={1}>Späť na referencie</AniLink>
        <AniLink className="next-project" cover direction="left" to="/referencie/tasty" bg="#000" duration={1}>Ďalší projekt</AniLink>
        
      </div>

    </Layout>
  );

}
