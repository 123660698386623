import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Logo from "../resources/images/Elliate_logo_white.svg"


export default function Footer() {

 
  return (
    <>
    <div className="container">
      
      <div className="row section-pd section-pd-bottom">
        <div className="footer-cta">
          <div className="title">
            Nenašli ste čo ste hľadali?
          </div>
          <p>Dohodnite si s nami konzultáciu! Na niečo prídeme 🤘😄</p>
          <div className="cta-button">
          <AniLink cover direction="left" to="/kontakt" bg="#000" duration={1} className={"color-button"}>
                    Konzultovať
                  </AniLink>
          </div>
        </div>
        
      </div>
    </div>  
      
      <div className="copyright-holder">
        <div className="container">
          <div className="copyright">
          <div className="logo">
            <img src={Logo} alt="Logo"></img>
          </div>
          <div className="copy">© {new Date().getFullYear()} Elliate s.r.o.</div>
          <div className="links">
          <AniLink cover direction="left" to="/ochrana-osobnych-udajov" bg="#000" duration={1} >
            Ochrana osobných údajov
            </AniLink>
          </div>
          </div>
          </div>
        </div>
      
    
    </>
    )
  
}


